
.calculation {
  input.size {
    width: 4em;
    display: inline-block;
  }

  .uploadForm {
    min-height: 250px;
    margin-bottom: 20px;
    //width: 150px;
    //height: 150px;
  }
  .panel-heading .checkbox {
    margin-top: 0;
  }
}