
.h1, h1 {
  font-size: 30px;
}

.h3, h3 {
  font-size: 24px;
}

.h3, h3 {
  font-size: 20px;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@media only screen and (max-width: 420px) {
  body.u1 .hidden-login-click img {background-color: white}
}

@media only screen and (min-width: 420px) {

  .h1, h1 {
    font-size: 36px;
  }

  .h3, h3 {
    font-size: 30px;
  }

  .h3, h3 {
    font-size: 24px;
  }

}


@media only screen and (min-width: 420px) and (max-width: 767px) {
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  body.u1 .hidden-login-click img {background-color: grey}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  body.u1 .hidden-login-click img {background-color: yellow}
  #modal-diagram .col-md-6:nth-child(2n+1) {
    clear:left;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body.u1 .hidden-login-click img {background-color: green}
}

/* Very large devices (large desktops, 1600px and up) */
@media (min-width: 1600px) {
  body.u1 .hidden-login-click img {background-color: blue}
}

/* Super large devices (large desktops, 2000px and up) */
@media (min-width: 2000px) {
  body.u1 .hidden-login-click img {background-color: black}
}

/*
@media only screen and (min-width:775px){
}
@media only screen and (min-width:1000px){
}
@media only screen and (min-width:1215px){
}



@media only screen and (min-width: 1200px) {
	.hidden-login-click img {background-color: grey}

}

@media only screen and (min-width: 992px) {
	.hidden-login-click img {background-color: yellow}
}

@media only screen and (min-width: 768px) {
	.hidden-login-click img {background-color: green}

}

@media only screen and (max-width: 1200px) {

	.hidden-login-click img {background-color: red}
}

@media only screen and (max-width: 992px) {


	.hidden-login-click img {background-color: blue}

}


@media only screen and (max-width: 768px) {


	.hidden-login-click img {background-color: orange}

}


@media only screen and (max-width: 420px) {

	.hidden-login-click img {background-color: white}


}*/