
.breadcrumb-wrapper {
  .breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 0;
    ul {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .breadcrumb-wrapper {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
    .row {
      margin-left: 0;
    }
    .breadcrumb {
      padding: 8px 15px;
      margin-bottom: 20px;
      list-style: none;
      ul {
        margin-bottom: 0;
      }
    }

  }
}

$blue-gray 			  : $grey_bg;
$blue-gray-darken 	  : $green_lighter_transparent25;
$blue 				  : $green;
$blue-darken 		  : $dark_green;
$green-darken 		  : $green_light_transparent25;

.breadcrumb>li+li:before {
  padding: 0;
  color: #ccc;
  content: "";
}

.breadcrumb-wrapper {
  .breadcrumb {
    padding: 8px 0;
    margin-bottom: 20px;
    background-color: transparent;
  }
}

.breadcrumb{
  list-style:none;
  display: inline-block;
  font-size: 12px;
  .icon{
    font-size: 14px;
  }

  li{
    //float:left;
    margin-bottom: 5px;
    a{
      color:#FFF;
      display:block;
      background: $blue;
      text-decoration: none;
      position:relative;
      height: 40px;
      line-height:40px;
      padding: 0 10px 0 5px;
      text-align: center;
      margin-right: 23px;
    }
    &:nth-child(even){
      a{
        background-color: $blue-darken;
        &:before{
          border-color:$blue-darken;
          border-left-color:transparent;
        }
        &:after{
          border-left-color:$blue-darken;
        }
      }
    }
    &:first-child{
      a{
        padding-left:15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        //@include border-radius(4px 0 0 4px);
        &:before{
          border:none;
        }
      }
    }
    &:last-child{
      a{
        padding-right:15px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        //@include border-radius(0 4px 4px 0);
        &:after{
          border:none;
        }
      }
    }

    a{
      &:before,
      &:after{
        content: "";
        position:absolute;
        top: 0;
        border:0 solid $blue;
        border-width:20px 10px;
        width: 0;
        height: 0;
      }
      &:before{
        left:-20px;
        border-left-color:transparent;
      }
      &:after{
        left:100%;
        border-color:transparent;
        border-left-color:$blue;
      }
      &:hover{
        background-color: $green;

        &:before{
          border-color:$green;
          border-left-color:transparent;
        }
        &:after{
          border-left-color:$green;
        }
      }
      &:active{
        background-color: $green-darken;

        &:before{
          border-color:$green-darken;
          border-left-color:transparent;
        }
        &:after{
          border-left-color:$green-darken;
        }
      }
    }
  }
}