//@import url(//fonts.googleapis.com/css?family=Open+Sans:400,600,700,800|Roboto:400,500,300,700);
///* open-sans-regular - latin */
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
  url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url('../fonts/open-sans-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v13-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v13-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: url('../fonts/open-sans-v13-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//  url('../fonts/open-sans-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../fonts/open-sans-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../fonts/open-sans-v13-latin-700.woff') format('woff'), /* Modern Browsers */
//  url('../fonts/open-sans-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../fonts/open-sans-v13-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
/* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  src: url('../fonts/open-sans-v13-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
//  url('../fonts/open-sans-v13-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../fonts/open-sans-v13-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../fonts/open-sans-v13-latin-800.woff') format('woff'), /* Modern Browsers */
//  url('../fonts/open-sans-v13-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../fonts/open-sans-v13-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}

/* roboto-300 - latin */
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 300;
//  src: url('../fonts/roboto-v15-latin-300.eot'); /* IE9 Compat Modes */
//  src: local('Roboto Light'), local('Roboto-Light'),
//  url('../fonts/roboto-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../fonts/roboto-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../fonts/roboto-v15-latin-300.woff') format('woff'), /* Modern Browsers */
//  url('../fonts/roboto-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../fonts/roboto-v15-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
//}
///* roboto-regular - latin */
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 400;
//  src: url('../fonts/roboto-v15-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Roboto'), local('Roboto-Regular'),
//  url('../fonts/roboto-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../fonts/roboto-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../fonts/roboto-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
//  url('../fonts/roboto-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../fonts/roboto-v15-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
//}
///* roboto-500 - latin */
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 500;
//  src: url('../fonts/roboto-v15-latin-500.eot'); /* IE9 Compat Modes */
//  src: local('Roboto Medium'), local('Roboto-Medium'),
//  url('../fonts/roboto-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../fonts/roboto-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../fonts/roboto-v15-latin-500.woff') format('woff'), /* Modern Browsers */
//  url('../fonts/roboto-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../fonts/roboto-v15-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
//}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/roboto-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v15-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/*font-family: 'Helvetica Inserat'*/
/*font-family: 'Helvetica Inserat Pro Roman'*/


/**********************************************
	Fonts
**********************************************/

body {
  font-family: $sans_serif;
  font-size: 16px;
  line-height: 1.5em;
}

.footer, .topaddress {
  font-size: 14px;
}

.content .swiper-container {
  .global-title {
    h2 {
      font-size: 50px;
    }
    p {
      font-size: 24px;
    }
  }
  .slide2 .global-title h2 {
    font-size: 40px;
  }
}

[class^="icon-"].no-margin-left:before, [class*=" icon-"].no-margin-left:before {
  margin-left: 0;
}

.leftsubmenu {
  menu ul.list li.headline {
    font-weight: 600;
    font-size: 16px;
  }
  .dropdown button {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
  }
}

.slide-title {
  p, h2, h3 {
    font-family: $title_font;
    font-weight: 700;
  }
}

.bottomfooter {
  font-size: 90%;
}

.content h1 {max-width: 48em;}
.content h2 {max-width: 44em;}
.content h3 {max-width: 40em;}
.content p {max-width: 36em;}