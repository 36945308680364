.news-box {
  margin-bottom: 20px;
}
.news-box__content__h2 {
  margin-top: 15px;
}

.news-box__content__h3 {
  margin-top: 15px;
}

.news-box__header {
  display: block;
}