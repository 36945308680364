@media (min-width: 768px) {
  .navbar-form .form-control {
    width: 100%;
  }
}
.selectize-control.product-search .selectize-dropdown [data-selectable] {
  border-bottom: 1px solid rgba(0,0,0,0.05);
  height: 60px;
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 10px 10px 10px 60px;
}
.selectize-control.product-search .selectize-dropdown [data-selectable]:last-child {
  border-bottom: 0 none;
}
.selectize-control.product-search .selectize-dropdown .by {
  font-size: 11px;
  opacity: 0.8;
}
.selectize-control.product-search .selectize-dropdown .by::before {
  content: 'by ';
}
.selectize-control.product-search .selectize-dropdown .name {
  font-weight: bold;
  margin-right: 5px;
}
.selectize-control.product-search .selectize-dropdown .description {
  font-size: 12px;
  color: #a0a0a0;
}
.selectize-control.product-search .selectize-dropdown .actors,
.selectize-control.product-search .selectize-dropdown .description,
.selectize-control.product-search .selectize-dropdown .title {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectize-control.product-search .selectize-dropdown .actors {
  font-size: 10px;
  color: #a0a0a0;
}
.selectize-control.product-search .selectize-dropdown .actors span {
  color: #606060;
}
.selectize-control.product-search .selectize-dropdown img {
  left: 10px;
  position: absolute;
  border-radius: 3px;
  background: rgba(0,0,0,0.04);
}
.selectize-control.product-search .selectize-dropdown .meta {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 10px;
}
.selectize-control.product-search .selectize-dropdown .meta li {
  margin: 0;
  padding: 0;
  display: inline;
  margin-right: 10px;
}
.selectize-control.product-search .selectize-dropdown .meta li span {
  font-weight: bold;
}
.selectize-control.product-search::before {
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  content: ' ';
  z-index: 2;
  position: absolute;
  display: block;
  top: 12px;
  right: 34px;
  width: 16px;
  height: 16px;
  background: url(/images/interface/ajax-loader_bw.gif);
  background-size: 16px 16px;
  opacity: 0;
}
.selectize-control.product-search.loading::before {
  opacity: 0.4;
}