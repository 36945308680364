.contact #gmap {
  width: 100%;
  height: 570px;
}

.contactform textarea.form-control {
  height: 300px;
}

//.contact .contact-logo {
//  height: 44px;
//  margin: 0 0 20px 0;
//  background-image: url(/local/media/_img/logo/ict_suedwerk.png);
//}
