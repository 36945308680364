.lazySrc,
.lazyBg {
  position: relative;
}
.lazySrc::before,
.lazyBg::before {
  width: 40px;
  height: 40px;
  content: ' ';
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  left: calc(50% - 20px);
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*transform-origin: 0 0 0;*/
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}
//
//.lazyBg {
//  position: relative;
//  &::before {
//    width: 40px;
//    height: 40px;
//    content: ' ';
//    display: inline-block;
//    margin: 0;
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate((-50%) -50%);
//    -webkit-transform: translate((-50%) -50%);
//    background-color: #333;
//    border-radius: 100%;
//    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
//    animation: sk-scaleout 1.0s infinite ease-in-out;
//  }
//}
//
//@-webkit-keyframes sk-scaleout {
//  0% {
//    -webkit-transform: scale(0);
//  }
//
//  100% {
//    -webkit-transform: scale(1);
//    opacity: 0;
//  }
//}
//
//
//@keyframes sk-scaleout {
//  0% {
//    -webkit-transform: scale(0);
//    transform: scale(0);
//  }
//
//  100% {
//    -webkit-transform: scale(1);
//    transform: scale(1);
//    opacity: 0;
//  }
//}
