@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?6258646');
  src: url('../fonts/fontello.eot?6258646#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?6258646') format('woff2'),
       url('../fonts/fontello.woff?6258646') format('woff'),
       url('../fonts/fontello.ttf?6258646') format('truetype'),
       url('../fonts/fontello.svg?6258646#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?6258646#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-basket:before { content: '\e800'; } /* '' */
.icon-basket-1:before { content: '\e801'; } /* '' */
.icon-basket-2:before { content: '\e802'; } /* '' */
.icon-mail:before { content: '\e803'; } /* '' */
.icon-mail-1:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-down-dir:before { content: '\e806'; } /* '' */
.icon-left-dir:before { content: '\e807'; } /* '' */
.icon-right-dir:before { content: '\e808'; } /* '' */
.icon-up-dir:before { content: '\e809'; } /* '' */
.icon-cog:before { content: '\e80a'; } /* '' */
.icon-cog-circled:before { content: '\e80b'; } /* '' */
.icon-ok:before { content: '\e80c'; } /* '' */
.icon-upload:before { content: '\e80d'; } /* '' */
.icon-info-1:before { content: '\e80e'; } /* '' */
.icon-print:before { content: '\e80f'; } /* '' */
.icon-user:before { content: '\e810'; } /* '' */
.icon-vcard:before { content: '\e811'; } /* '' */
.icon-user-1:before { content: '\e812'; } /* '' */
.icon-picture:before { content: '\e813'; } /* '' */
.icon-chart-bar:before { content: '\e814'; } /* '' */
.icon-chart-area:before { content: '\e815'; } /* '' */
.icon-chart:before { content: '\e816'; } /* '' */
.icon-download:before { content: '\e817'; } /* '' */
.icon-trash-empty:before { content: '\e818'; } /* '' */
.icon-truck:before { content: '\e819'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?kw35c6');
  src:  url('../fonts/icomoon.eot?kw35c6#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?kw35c6') format('truetype'),
    url('../fonts/icomoon.woff?kw35c6') format('woff'),
    url('../fonts/icomoon.svg?kw35c6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-box:before {
  content: "\e900";
}
.icomoon-package:before {
  content: "\e900";
}
.icomoon-layers:before {
  content: "\e901";
}
.icomoon-stack:before {
  content: "\e901";
}
.icomoon-planes:before {
  content: "\e901";
}
.icomoon-manage:before {
  content: "\e901";
}
.icomoon-circle-compass:before {
  content: "\e902";
}
.icomoon-distance:before {
  content: "\e902";
}
.icomoon-automobile:before {
  content: "\e903";
}
.icomoon-car:before {
  content: "\e903";
}
.icomoon-industry:before {
  content: "\e904";
}
.icomoon-phonelink_setup:before {
  content: "\e905";
}
.icomoon-compare:before {
  content: "\e906";
}
.icomoon-microscope:before {
  content: "\e907";
}
.icomoon-git-compare:before {
  content: "\e908";
}
.icomoon-stack2:before {
  content: "\e92e";
}
.icomoon-barcode:before {
  content: "\e937";
}
.icomoon-calculator:before {
  content: "\e940";
}
.icomoon-mobile:before {
  content: "\e958";
}
.icomoon-magic-wand:before {
  content: "\e997";
}
.icomoon-power-cord:before {
  content: "\e9b7";
}
.icomoon-earth:before {
  content: "\e9ca";
}
.icomoon-make-group:before {
  content: "\ea58";
}

