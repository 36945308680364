
.td.light-blue-color,
.badge.light-blue-color {
  background-color: #6293f0;
  color: #ffffff;
}

.td.light-grey-color,
.td.light-gray-color,
.badge.light-grey-color,
.badge.light-gray-color {
  background-color: #a9a9a9;
  color: #ffffff;
}

.td.grey-color,
.rd.gray-color,
.badge.grey-color,
.badge.gray-color {
  background-color: #808080;
  color: #ffffff;
}