/**********************************************
	Swiper Overwrites
**********************************************/

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background: $green;
}

.swiper-pagination-bullet {
  opacity: 1;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
  background: transparent;
  border: 2px solid $green;
}

.swiper-button-next,
.swiper-button-prev,
.pagaination {
  background-image: none;
  background: $green;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

.swiper-button-next:before,
.swiper-button-prev:before {
  content: '';
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: right;
  width: 25px;
  font-size: 40px;
  margin: 2px 0 0 0;
  color: #ffffff;
}
.swiper-button-prev:before { content: '\F007';}
.swiper-button-next:before { content: '\F006'; }

.fullscreen .swiper-container {
  width: 100%;
  height: 100%;
}
.fullscreen .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.fullscreen .swiper-slide .bgimage {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 15px;
}

/**********************************************
	Local Swiper Settings
**********************************************/



.content {
  .swiper-container {
    position: relative;
    .slide-title {
      position: absolute;
      bottom: 10%;
      left: 5%;
      z-index:3;
      width:90%;
      text-align: left;
    }
    .slide0 .slide-title {
      bottom: 15%;
    }
    .slide2 .slide-title {
      bottom: 25%;
    }
    .slide1 .slide-title {
      bottom: 20%;
      left: auto;
      right: 5%;
    }
    .slide-title {
      p, .global-title__title, h3 {
        font-family: Roboto, 'Open Sans', sans-serif;
        font-weight: 700;
        background-color: #fff;
        background-color: rgba(255,255,255,0.85);
        display: inline-block;
        color: $green;
        padding: 10px;
        line-height: 1em;
      }
    }
    .slide2 .slide-title,
    .slide-title.swiper_left-center,
    .colorvers {
      p, .global-title__title, h3 {
        background-color: $green;
        background-color: rgba(121, 181, 51, 0.75);
        color: #fff;
      }
    }
    .slide1 .slide-title,
    .slide-title.swiper_right-bottom ,
    .colordark {
      p, .global-title__title, h3 {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
        color: #fff;
      }
    }
    .slide1 .slide-title,
    .slide-title.swiper_right-bottom,
    .slide2 .global-title {
      text-align: right;
    }
  }
  .swiper-container.swiper-mask {
    .slide-title {
      p, .global-title__title, h3 {
        background-color: transparent;
        color: #fff;
        padding: 0 0 10px 0;
      }
    }
    .slide2 .slide-title,
    .slide-title.swiper_left-center,
    .colorvers {
      p, .global-title__title, h3 {
        background-color: transparent;
        color: #fff;
      }
    }
    .slide1 .slide-title,
    .slide-title.swiper_right-bottom ,
    .colordark {
      p, .global-title__title, h3 {
        background-color: transparent;
        color: #fff;
      }
    }
    .slide1 .slide-title,
    .slide-title.swiper_right-bottom,
    .slide2 .global-title {
      text-align: right;
    }
  }
}

.mask-content::after,
.swiper-mask .bgimage::after {
  content: "";
}
.mask-content::after,
.swiper-mask .bgimage::after,
.content  .swiper-container .mask {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.25);
  @include background-image(linear-gradient(top, rgba(255,255,255,0.25) 0%, rgba(100,100,100,0.25) 70%, rgba(0,0,0,0.25) 100%));
}


.bgimageswiper .slide-title .global-title__title {
  font-size: 25px;
  font-size: 7vw;
}

@media (min-width: 480px) {
  .bgimageswiper .slide-title .global-title__title {
    font-size: 25px;
    font-size: 8vw;
  }
}

@media (min-width: 768px) {
  .bgimageswiper .slide-title .global-title__title {
    font-size: 50px;
    margin: 10px 0 10px 0;
  }
  .slide-title {
    left: 10%;
  }
  .slide1 .slide-title {
    left: auto;
    right: 5%;
  }
}