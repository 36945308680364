body.draft {

  .content {
    position: relative;
    min-height: calc(100vh - 44px);
    .center.middle {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      width: 100%;
    }
    h1 {
      margin: 0;
      text-align: center;
      a {
        background-image: url(/local/media/_img/logo/ict-suedwerk.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        width: 100%;
        height: 400px;
        height: 10vmin;
        margin: 0;
        display: inline-block;
      }
      span {
        display: none;
      }
    }
    .text {
      padding-top: 1em;
      width: 50vmin;
      position: relative;
      left: 50%;
      @include transform(translateX(-50%));
    }
  }

}