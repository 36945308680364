
.footer {
  min-height: 200px;
  background-color: #72706f;
  padding: 20px 0 20px 0;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
    &:focus, &:hover {
      text-decoration: none;
    }
  }
  .nav > li > a {
    padding: 3px 0 3px 0;
    &:focus, &:hover {
      text-decoration: underline;
      background-color: transparent;
    }
  }
  h5 {
    font-size: 18px;
    margin-top: 0;
  }
  .helptext p {
    margin-bottom: 16px;
  }
  .news {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      margin: 10px 0px !important;
      padding: 0 0 10px 0;
      margin: 10px 0 10px 0;
      font-size: 12px;
      line-height: 17px;
      border-bottom: 1px dashed #f1f1f1;
      h6 {
        font-size: 14px;
        line-height: 24px;
      }
      time {
        display: block;
        font-size: 11px;
      }
    }
  }
}

.bottomfooter {
  background-color: $green;
  p {
    margin-top: 10px;
    color: #ffffff;
  }
  a {
    color: #fff;
  }
  .footer-menu, .copyright {
    margin-bottom: 10px;
  }
  .copyright {
    margin-right: 5px;
  }
  .footer-menu nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .footer-menu nav ul li {
    display: inline-block;
  }
  .footer-menu nav ul li:after {
    content: ' | ';
    margin: 0 5px;
    color: #fff;
  }
  .footer-menu nav ul li:last-child:after {
    content: '';
    margin: 0;
  }
  .footer-menu nav ul li a {}
}

@media only screen and (min-width: 500px) {
  .copyright:after {
    content: ' | ';
    margin: 0 5px;
    color: #fff;
  }
  .footer-menu, .copyright {
    float: left;
    margin-top: 10px;
  }
}