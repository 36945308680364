
div.missionmessage,
div.missionmessageerror,
div.missionmessagesuccess,
div.missionmessagehint {
  margin: 0 0 2em 0;
  padding: 5px 8px 5px 8px;
  //color: #fff;
  border: 2px solid #bd3535;
  background-color: #fff;
}

div.missionmessagesuccess,
div.missionmessagehint {
  border: 2px solid #76b839;
  background-color: #fff;
}

div.missionmessage img,
div.missionmessageerror img,
div.missionmessagesuccess img,
div.missionmessagehint img {
  float: left;
}

div.missionmessagehint div.mmtext,
div.missionmessageerror div.mmtext,
div.missionmessagesuccess div.mmtext {
  //color: #fff;
  padding: 0 0 0 50px;
}

div.missionmessageerror img,
div.missionmessagesuccess img,
div.missionmessagehint img {
  margin-right: 10px;
}

div.missionmessageerror div.mmclearleft,
div.missionmessagesuccess div.mmclearleft,
div.missionmessagehint div.mmclearleft {
  clear: left;
}