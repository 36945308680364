@mixin prefix($map, $vendors: webkit moz ms o) {
  @each $prop, $value in $map {
    @if $vendors {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $prop}: #{$value};
      }
    }
    // Dump regular property anyway
    #{$prop}: #{$value};
  }
}