
/**********************************************
	Products
**********************************************/

.leftsubmenu {
  menu {
    margin: 20px 0 0 0;
    padding: 0;
  }
  ul.list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  menu ul.list {
    > li {
      padding: 0 0 5px 0;
      a {
        display: block;
        cursor: pointer;
      }
    }
    li {
      &.headline {
        margin: 10px 0 5px 0;
      }
      position: relative;
      a {
        &:hover:before, &:focus:before {
          content: '\F006';
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          color: $green;
          position: absolute;
          left: -10px;
          top: 3px;
          font-size: 12px;
          /*font-weight: 600;*/
        }
      }
      &.active a:before {
        content: '\F006';
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        color: $green;
        position: absolute;
        left: -10px;
        top: 3px;
        font-size: 12px;
        /*font-weight: 600;*/
      }
    }
    > li {
      &.active a {
        font-weight: 600;
      }
      a {
        &:hover, &:focus {
          text-decoration: none;
          color: $green;
        }
      }
      ul li {
        padding: 0 0 2px 0;
      }
    }
  }
  ul.list ul {
    padding: 5px 0 0 15px;
  }
  .dropdown {
    margin-top: 15px;
    margin-bottom: 20px;
    button {
      padding: 4px 4px 4px 45px;
      text-align: left;
      /*background-color: #f5f5f5;*/
      border-color: #f5f5f5;
      position: relative;
      white-space: normal;
      box-shadow: 1px 1px 2px #ccc;
      .icon-down-open {
        position: absolute;
        left: 2px;
        top: 6px;
      }
      .caret {
        display: none;
        position: absolute;
        right: 12px;
        top: 12px;
        border-top: 10px dashed;
        border-top: 10px solid\9;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
      }
    }
  }
}

.badge {
  &.blue {
    background-color: cornflowerblue;
  }
  &.darkgrey {
    background-color: darkgrey;
  }
  &.grey {
    background-color: grey;
  }
}

.highlight-box {
  /*background-color: $green;*/
  h4 {
    margin: 0;
  }
}

.description {}

a.read_more {
  color: $green;
}

.more_text {
  display: none;
}

.table-product {
  tr td {
    &:first-child, &.unit {
      /*background-color: $green;*/
      /*color: #fff;*/
    }
  }
  th {
    /*background-color: $green;*/
    /*color: #fff;*/
    white-space: nowrap;
  }
  tr td {
    &:first-child .unit {
      display: none;
    }
    &.unit {
      text-align: center;
    }
  }
}

.tab-pane {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.product-actions {
  ul.dropdown-menu {
    list-style-image: none;
    padding-left: 0;
    li {
      list-style-image: none;
      padding-left: 0;
      a {
        padding-left: 10px;
        min-height: 35px;
      }
    }
  }
}

.product-table {
  font-size: 13px;
  padding: 20px 0 0 0;
}
.product-content {
  padding: 20px 0 0 0;
}

.product-content > .textcontent > h2 {
  margin-top: 10px;
}

.product-content .tab-content {
  margin-bottom: 20px;
}

///* RESPONSIVE DATATABLE PRODUCT TABLE */
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: 10px;
}

@media only screen and (min-width: 420px) {
  .product-table {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dt-buttons {
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
  div.dataTables_wrapper div.dt-buttons a {
    float: none;
  }
}

///* RESPONSIVE PRODUCT TABLE */
//
//.stacktable.large-only { display: none; }
//.stacktable.small-only {
//  display: table;
//  td.unit {
//    //display: none;
//  }
//}
//.table-product.small-only tr td.unit {
//  text-align: left;
//}
//
//@media (min-width: 992px) {
//  .stacktable.large-only { display: table; }
//  .stacktable.small-only { display: none; }
//}

//.product-category-table {
//  //overflow-x: auto;
//  table tbody td:nth-child(1),
//  table thead th:nth-child(1) {
//    //position: fixed;
//  }
//}