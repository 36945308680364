.button-area {
  margin: 20px 0 20px 0;
  &.btn-group-vertical.pull-right {
    margin: 0 0 20px 20px;
  }
  button {
    position: relative;
    margin: 0 10px 20px 0;
  }
  &.btn-group-vertical button {
    margin: 0;
  }
  button {
    .button-icon {
      font-size: 25px;
      position: absolute;
      top: 4px;
      left: 0;
    }
    .button-text {
      margin-left: 25px;
      line-height: 35px;
    }
    &.btn-splitter .button-text {
      margin-right: 35px;
    }
  }
  &.btn-group-vertical.pull-right {
    .button-text, .btn {
      text-align: left;
    }
  }
  button .splitter-icon {
    font-size: 25px;
    position: absolute;
    top: 4px;
    right: 0;
  }
}


.btn-ict {
  color: #ffffff;
  background-color: $green;
  border-color: transparent;
  &:hover, &:focus, &:active, &.active {
    color: #ffffff;
    background-color: $dark_green;
    border-color: $dark_green;
  }
}

.open .dropdown-toggle.btn-ict {
  color: #ffffff;
  background-color: $dark_green;
  border-color: $dark_green;
}

.btn-ict {
  &:active, &.active {
    background-image: none;
  }
}

.open .dropdown-toggle.btn-ict {
  background-image: none;
}

.btn-ict {
  &.disabled, &[disabled] {
    background-color: $green;
    border-color: $green;
  }
}

fieldset[disabled] .btn-ict {
  background-color: $green;
  border-color: $green;
}

.btn-ict {
  &.disabled:hover, &[disabled]:hover {
    background-color: $green;
    border-color: $green;
  }
}

fieldset[disabled] .btn-ict:hover {
  background-color: $green;
  border-color: $green;
}

.btn-ict {
  &.disabled:focus, &[disabled]:focus {
    background-color: $green;
    border-color: $green;
  }
}

fieldset[disabled] .btn-ict:focus {
  background-color: $green;
  border-color: $green;
}

.btn-ict {
  &.disabled:active, &[disabled]:active {
    background-color: $green;
    border-color: $green;
  }
}

fieldset[disabled] .btn-ict:active {
  background-color: $green;
  border-color: $green;
}

.btn-ict {
  &.disabled.active, &[disabled].active {
    background-color: $green;
    border-color: $green;
  }
}

fieldset[disabled] .btn-ict.active {
  background-color: $green;
  border-color: $green;
}

.btn-ict .badge {
  color: $green;
  background-color: #ffffff;
}

