
$collapse: 1000px;
$collapse_max: 1000px - 1;

/**********************************************
	Collapse Navbar @ 1000
**********************************************/


/* Big Device Navigation */
@media (min-width: $collapse) {

  header .dropdown-menu>li>a:focus,
  header .dropdown-menu>li>a:hover {
    color: #fff;
    text-decoration: none;
    background-color: $green;
  }

  header .nav > li > a {
    padding-left: 0;
    padding-right: 30px;
  }

  header .dropdown-menu {
    background-color: #ffffff;
    background-color: rgba(255,255,255,0.95);
  }

  header .dropdown-menu>li>a {
    //color: #ffffff;
  }
  .topaddress ul.contact>li+li:before,
  .topaddress ul.konto>li+li:before {
    padding: 0 5px;
    color: #fff;
    content: "|";
  }

  .lang-dropdown {
    float: right;
    margin-right:-15px;
  }

  .navbar-form {
    float: right;
  }


  header .navbar .container {
    padding-right: 0;
  }

  header .dropdown-menu .col2 {
    padding-left: 0;
  }

  header .navbar {
    border-radius: 0;
    //border: none;
  }

  .navbar-default {
    background-color: #ffffff;
    background-color: rgba(255,255,255,0.95);
    border-color: transparent;
    border-bottom-color: $grey_bg;
  }

}

@media (max-width: $collapse_max) {

  header .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }

  header .navbar-nav .open .dropdown-menu {
    border-bottom: 1px solid #fff;
    max-height: 300px;
    max-height: calc(100vh - 325px);
    overflow-y: auto;
  }

  header .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

//For Bootstrap 3.3.x, here is the working CSS to override the navbar breakpoint.
//Change 991px to the pixel dimension of the point at which you want the navbar to collapse...

@media (max-width: $collapse) {
  .navbar-header {
    float: none;
  }
  .navbar .navbar-left,.navbar .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }


}

@media (max-width: $collapse) {
  //.navbar-header {
  //  float: none;
  //}
  //.navbar-left,.navbar-right {
  //  float: none !important;
  //}
  //.navbar-toggle {
  //  display: block;
  //}
  //.navbar-collapse {
  //  border-top: 1px solid transparent;
  //  box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  //}
  //.navbar-fixed-top {
  //  top: 0;
  //  border-width: 0 0 1px;
  //}
  //.navbar-collapse.collapse {
  //  display: none!important;
  //}
  //.navbar-nav {
  //  float: none!important;
  //  margin-top: 7.5px;
  //}
  //.navbar-nav>li {
  //  float: none;
  //}
  //.navbar-nav>li>a {
  //  padding-top: 10px;
  //  padding-bottom: 10px;
  //}
  //.collapse.in{
  //  display:block !important;
  //}
}

@media only screen and (max-width: $collapse) {

  //.navbar-collapse li {
  //  float: none;
  //  padding: 5px 0 5px 0;
  //}
  //
  //.navbar-collapse li ul.submenu li {
  //  padding: 5px 0 5px 15px;
  //}
  //
  //.navbar-collapse {
  //  position: absolute;
  //  left: -50px;
  //  top: 30px;
  //  width: 120%;
  //}
  //
  //.navbar-collapse .submenu {
  //  display: block !important;
  //}
  //
  //.navbar-collapse ul::after {
  //  content: ' ';
  //  clear: both;
  //}
  //
  //header .navbar-collapse ul.submenu {
  //  position: static;
  //}
}

@media only screen and (max-width: $collapse) {

  //.navbar-collapse {
  //  //background-color: #e7007b;
  //}
  //
  //header .navbar-collapse li.active,
  //header li.active > a,
  //header li:hover > a,
  //header .navbar-collapse a:hover,
  //header .navbar-collapse a:active,
  //header .navbar-collapse a:focus,
  //header .navbar-collapse a.selected,
  //header .navbar-collapse a.active {
  //  //color: #ffed00;
  //}
  //
  //header .navbar-collapse nav {
  //  transform-origin: 0 0;
  //  position: relative;
  //  top: 0;
  //  -webkit-backface-visibility: hidden;
  //  backface-visibility: hidden;
  //  padding: 25px 15px 15px 45px;
  //  display: inline-block;
  //  height: auto;
  //}
  //
}
