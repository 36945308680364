.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: #fff;
  background-color: $green;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  color: #fff;
  background-color: $green;
}

.selectize-input.focus,
.form-control:focus {
  border-color: $green;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(118,184,57,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(118,184,57,.6);
}

.has-success .selectize-input {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  color: #fff;
  background-color: $green;
  border-color: $green;
}
.pagination>li>a, .pagination>li>span {
  color: $green;
  background-color: #fff;
  border: 1px solid $green;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
  color: #fff;
  background-color: $green;
  border-color: $green;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
}