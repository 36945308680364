// Needs latest Compass, add '@import "compass"' to your scss
// Permalink - use to edit and share this gradient:
// http://colorzilla.com/gradient-editor/#76b839+0,76b839+34,8bd843+62,8bd843+67,76b839+89,76b839+100
.panel-default>.panel-heading.green-gradient,
.green-gradient {
  background-color: #76b839; // Old browsers
  //@include filter-gradient(#76b839, #76b839, vertical); // IE6-9
  @include background-image(linear-gradient(top, #76b839 0%, #76b839 34%, #8bd843 62%, #8bd843 67%, #76b839 89%, #76b839 100%));
}

