

.topaddress {
  background-color: $green;
  color: #fff;
  a {
    color: #fff;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  ul {
    .topaddress-account__li2 {
      display: none;
    }
    &.contact {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &.topaddress-account__ul {
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        margin-left: 5px;
      }
    }
    &.contact > li + li:before, &.topaddress-account__ul > li + li:before {
      padding: 0 5px;
      color: #fff;
      content: "|";
    }
  }
}

header {
  .logo-col__headline {
    margin-top: 13px;
    a {
      background-image: url(/local/media/_img/logo/ict_suedwerk.png);
      background-image: url(/local/media/_img/logo/ict-suedwerk.svg);
      background-repeat: no-repeat;
      background-position: 0px 0px;
      background-size: auto 100%;
      width: 300px;
      height: 70px;
      margin: 5px 0 0 0;
      display: block;
    }
    span {
      display: none;
    }
  }
  .navbar {
    margin-bottom: 0;
  }
  .navbar-header .navbar-logo a {
    background-image: url(/local/media/_img/logo/ict_suedwerk.png);
    background-image: url(/local/media/_img/logo/ict-suedwerk.svg);
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: auto 100%;
    height:42px;
    margin-top:4px;
    width:170px;
    position: absolute;
    right: 0;
    top:0;
  }
  .navbar-toggle {
    float: left;
    margin-left: 15px;
  }
}

.header-content {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-content .lang-dropdown button {
  text-transform: uppercase;
}

.lang-dropdown {
  float: left;
  margin-right:15px;
}

.lang-dropdown {
  display: none;
}

.navbar-form {
  float: left;
  padding-bottom: 0;
}

.menu-social span {
  display: inline-block;
  margin-right: 5px;
}

.header-content .dropdown, .navbar-form {
  padding-top: 5px;
  margin-top: 8px;
}

.header-content {
  .dropdown button {
    height: 36px;
  }
  .navbar-form {
    width: 100%;
  }
  .navbar-form {
    padding-right: 0;
  }

}
@media (min-width: 768px) {
  .navbar-form {
    margin-right: -15px;
  }
}

#custom-search-input {
  border: solid 1px #ccc;
  border-radius: 4px;
  background-color: #fff;
  input {
    border: 0;
    box-shadow: none;
  }
  button {
    margin: 2px 0 0 0;
    background: none;
    box-shadow: none;
    border: 0;
    color: #333;
    padding: 0 8px 0 10px;
    border-left: solid 1px #ccc;
    &:hover {
      border: 0;
      box-shadow: none;
      border-left: solid 1px #ccc;
    }
  }
  .glyphicon-search {
    font-size: 16px;
  }
  .input-group-btn {
    width:35px;
  }
}

.header-content.logobig .logo-col {
  height: 80px;
  position: relative;
  .logo-col__headline {
    position: absolute;
    z-index: 3;
    a {
      height: 80px;
      width: 350px;
    }
  }
}

header {
  .dropdown-menu {
    min-width: 200px;
    &.columns-2, &.columns-3 {
      min-width: 600px;
    }
  }
  .nav-products .dropdown-menu.columns-2 {
    min-width: 790px;
  }
  .dropdown-menu li {
    a {
      padding: 5px 15px;
      font-weight: 300;
    }
    &.inner-padding {
      padding: 5px 15px;
    }
    .form-group {
      margin-bottom: 5px;
    }
  }
  .multi-column-dropdown {
    list-style: none;
    padding: 10px;
    li a {
      display: block;
      clear: both;
      line-height: 1.428571429;
      color: #333;
      white-space: normal;
      &:hover {
        text-decoration: none;
        color: #fff;
        background-color: $green;
      }
    }
  }
}

.nav-thumb {
  display: block;
  width: 96px;
  height: 64px;
  float: left;
  margin: 0 10px 10px 0;
}

header .dropdown-menu {
  .text.small {
    padding-left: 106px;
    display: block;
  }
  .col1 .horizontal-separator {
    position: absolute;
    width: 1px;
    background-color: #e5e5e5;
    display: block;
    right: 5px;
    top: 10px;
    bottom: 10px;
  }
  .col2 .horizontal-separator {
    position: absolute;
    width: 1px;
    background-color: #e5e5e5;
    display: block;
    left: -6px;
    top: 10px;
    bottom: 10px;
  }
  .col1 {
    position: relative;
  }
  .col2 {
    i {
      float: left;
      font-size: 40px;
      color: $green;
      width: 45px;
      text-align: center;
      margin-left: -10px;
    }
    .nav-header, .text.small {
      padding-left: 45px;
      display: block;
    }
  }
}

header .navbar-default .navbar-nav>.active>a,
header .navbar-default .navbar-nav>.active>a:hover,
header .navbar-default .navbar-nav>.active>a:focus,
header .navbar-default .navbar-nav>.active.open>a,
header .navbar-default .navbar-nav>.active.open>a:hover,
header .navbar-default .navbar-nav>.active.open>a:focus {
  background-color: transparent;
  color: $dark_green;
}
header .navbar-default .navbar-nav>.open>a,
header .navbar-default .navbar-nav>.open>a:hover,
header .navbar-default .navbar-nav>.open>a:focus {
  background-color: transparent;
  color: #333;
}

#mainnav.affix,
#mainnav.affix-top {
  width: 100%;
  display: block;
}

#mainnav.affix {
  top: 0;
  z-index: 40;
  opacity: 0.95;
  filter: alpha(opacity=95);
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  -webkit-backface-visibility: hidden;
}

#mainnav.affix .header-content {
  display: none;
}

#mainnav.affix-top .navbar-logo {
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

#mainnav .navbar-logo {
  opacity:0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  -webkit-backface-visibility: hidden;
}

#mainnav.affix .navbar-logo {
  opacity: 1;
  filter: alpha(opacity=1);
}

header .navbar-header {
  position: relative;
  width:100%;
}

@media only screen and (min-width: 768px) {

  .topaddress {
    height: 26px;
  }
  .topaddress-account {
    padding: 0;
  }
  header .navbar-toggle {
    margin-left: 0;
  }
}

@media only screen and (min-width: 420px) {

}
