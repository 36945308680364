
.transition {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
}

.standard-transition {
  -webkit-transition-property: opacity, background, background-image;
  -moz-transition-property: opacity, background, background-image;
  -o-transition-property: opacity, background, background-image;
  transition-property: opacity, background, background-image;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
}

.slow-transition {
  -webkit-transition-property: opacity, background, background-image;
  -moz-transition-property: opacity, background, background-image;
  -o-transition-property: opacity, background, background-image;
  transition-property: opacity, background, background-image;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
}

.transform-transition {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
}