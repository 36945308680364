.uploadCol {
  height: auto;
}

.uploadForm {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border: 1px solid $grey_border;
  border-radius: 4px;
  &.dropzone {
    background: transparent;
    padding:0;
    .dz-message {
      margin:0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .dz-preview .dz-image {
      -webkit-border-radius:0;
      -moz-border-radius:0;
      border-radius:0;
      //width:150px;
      //height:150px;
      img {
        max-height: 120px;
      }
    }
    .dz-preview .dz-details {
      opacity: 0.75;
      padding-top: 2.5em;
      .delete {
        position: absolute;
        top: 3px;
        right: 0;
        font-size: 18px;
        z-index: 2;
        background-color: rgba(255,255,255,.4);
        padding: .1em .1em;
        border-radius: 3px;
        color: #000;
      }
    }
    .dz-preview .dz-image img {
      //visibility: hidden;
    }
    .dz-preview:hover {
      .dz-image {
        canvas {
          @include prefix((transform: scale(1.05, 1.05))); // Getting rid of that white bleed-in
          @include prefix((filter: blur(8px)), webkit); // Getting rid of that white bleed-in
        }
      }
    }
  }
  .dz-preview {
    background-color: $grey_border;
    //position: absolute;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
  }
  //.dz-image {
  //  overflow: hidden;
  //  width: 100%;
  //  height: 100%;
  //  text-align: center;
  //  margin: auto;
  //  position: relative;
  //  img {
  //    position: absolute;
  //    top: 50%;
  //    left: 50%;
  //    @include transform(translate(-50%, -50%));
  //    width: 100%;
  //  }
  //}
  //.dz-details, .dz-error-message, .dz-success-mark, .dz-error-mark {
  //  //display: none;
  //}
  .dz-message {
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 50%;
    width: 100%;
    font-size: em(12);
    font-size: 3vmin;
    @include transform(translate(0, -50%));
    span {
      display: block;
      text-align: center;
      color: $grey_text;
      position: relative;
    }

  }
}


