
.product-menu .product-menu__flexcontainer {
  @include align-items(stretch);
  @include display(flex);
  @include flex-direction(row);
  @include justify-content(flex-start);
  @include flex-wrap(wrap);
}

.product-menu .product-menu__flexcontainer > menu {
  min-width: 300px;
  max-width: 350px;
  @include flex(0 1 20%);
  margin-right: 20px;
}

@media only screen and (min-width: 420px) {
  .product-menu .product-menu__flexcontainer > menu {
    min-width: 350px;
    max-width: 380px;
    @include flex(0 1 20%);
  }
}