//colors
$color_seance_approx: #812588;
$color_mine_shaft_approx: #333;
$color_vida_loca_approx: #528028;
$white: #fff;
$color_celeste_approx: #ccc;
$color_shark_approx: #262626;
$color_wild_sand_approx: #f5f5f5;
$mercury: #e5e5e5;
$white_75: rgba(255,255,255,0.75);
$color_sushi_75_approx: rgba(121,181,51,0.75);
$black: #000;
$black_75: rgba(0,0,0,0.75);
$color_quill_gray_approx: #d7d5d4;
$cornflower_blue: cornflowerblue;
$color_shady_lady_approx: darkgrey;
$gray: grey;
$color_alto_approx: #ddd;
$color_dove_gray_approx: #72706f;
$seashell: #f1f1f1;

$color_sushi_approx: #76b839;
$green: #76b839;
$dark_green: #528028;
$green_light_transparent25: rgba(118,184,57,0.25);
$green_lighter_transparent25: rgba(139,216,67,0.25);

$grey_border: #ccc;
$grey_bg: #f5f5f5;
$grey_text: #777;

//fonts
$font_0: 'Open Sans';
$font_1: sans-serif;
$font_2: Roboto;
$font_3: fontello;
$sans_serif: $font_0,$font_1;
$title_font:  $font_2, $font_0, $font_1;

//urls
$url_0: url(//fonts.googleapis.com/css?family=Open+Sans:400,600,700,800|Roboto:400,500,300,700);
//$url_1: url(/mcms/isar148/_img/icons/ajax-loader-roller-b-s.gif);
$url_2: url(/local/media/_img/logo/ict_suedwerk.png);
$url_3: url(/local/media/_img/_d/dot.svg);

// Breakpoints
$smartphone-mq: 'screen and (max-width: 768px)';
$smartphone-tablet-mq: 'screen and (max-width: 900px)';
$tablet-desktop-mq: 'screen and (min-width: 769px)';
$desktop-mq: 'screen and (min-width: 901px)';
$tablet-mq: 'screen and (max-width: 900px) and (min-width: 769px)';
