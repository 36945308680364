
/**********************************************
	Global
**********************************************/

.fixfloat {
  clear: both;
  float: none;
  height: 0;
  font-size: 0;
  width: 0;
  line-height: 0px;
}

.clear {
  clear: both;
}

.clearleft {
  clear: left;
}

.clearright {
  clear: right;
}

.invisible {
  visibility: hidden;
  display: none;
}

.nopadding {
  padding: 0 !important;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-right {
  padding-right: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.nopadding-left {
  padding-left: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.nomargin-top {
  margin-top: 0 !important;
}

.nomargin-right {
  margin-right: 0 !important;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nomargin-left {
  margin-left: 0 !important;
}

.noborder {
  border: 0 none !important;
}

.noborder-top {
  border-top: none !important;
}

.noborder-right {
  border-right: none !important;
}

.noborder-bottom {
  border-bottom: none !important;
}

.noborder-left {
  border-left: none !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.hidden {
  visibility: hidden;
}

.invisible {
  height: 0;
}

.smaller {
  font-size: 90%;
}

.clickable {
  cursor: pointer;
}

.spacer-3, .spacer-5, .spacer-10, .spacer-20, .spacer-30, .spacer-40, .spacer-50 {
  font-size: 0;
  clear: both;
  float: none;
}

.spacer {
  height: 20px;
}

.spacer-3 {
  height: 3px;
}

.spacer-5 {
  height: 5px;
}

.spacer-10 {
  height: 10px;
}

.spacer-20 {
  height: 20px;
}

.spacer-30 {
  height: 30px;
}

.spacer-40 {
  height: 40px;
}

.spacer-50 {
  height: 50px;
}

.push-bottom-3 {
  margin-bottom: 3px;
}

.push-bottom-5 {
  margin-bottom: 5px;
}

.push-bottom-10 {
  margin-bottom: 10px;
}

.content .push-bottom-20, .push-bottom-20 {
  margin-bottom: 20px;
}

.push-bottom-30 {
  margin-bottom: 30px;
}

.push-bottom-40 {
  margin-bottom: 40px;
}

.push-bottom-50 {
  margin-bottom: 50px;
}

a:active {
  outline: none;
}

:focus {
  -moz-outline-style: none;
}

* {
  outline: none;
}

a::-moz-focus-inner, button::-moz-focus-inner {
  border: none;
}

input {
  &[type="reset"]::-moz-focus-inner, &[type="button"]::-moz-focus-inner, &[type="submit"]::-moz-focus-inner, &[type="file"] > input[type="button"]::-moz-focus-inner {
    border: none;
  }
}

::selection, input::selection {
  background: #e3e3e3;
}

img {
  border: none;
}

.cover {
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover-horizontal, .coverlogo, .coverlogo-horizontal {
  -o-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
}

.cover-vertical, .coverlogo-vertical {
  -o-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.rounded-corners {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

/*
.lazyBg {
	background-position: center center !important;
	-webkit-background-size: auto !important;
	-moz-background-size:  auto !important;
	-o-background-size:  auto !important;
	background-size:  auto !important;
	background-repeat: no-repeat;
	background-image: url("/mcms/isar148/_img/icons/ajax-loader-roller-b-s.gif") !important;
}
*/



.wbr {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}


/**********************************************
	Links
**********************************************/

a {
  color: $green;
  text-decoration: none;
  &:focus, &:hover {
    color: #528028;
    text-decoration: underline;
  }
}

/**********************************************
	Content
**********************************************/

.content {
  min-height: calc(100vh - 200px);
  .textcontent ul {
    list-style-image: url(/local/media/_img/_d/dot.svg);
    padding-left: 20px;
    li {
      list-style-image: url(/local/media/_img/_d/dot.svg);
      padding: 0 0 0 3px;
    }
  }
  section.maincontent {
    /*padding: 40px 0 40px 0;*/
  }
}

/**********************************************
	Sections
**********************************************/

section {
  &.product-placement {
    padding: 0 0 0 0;
  }
  position: relative;
  &::before {
    content: " ";
    display: table;
  }
  &::after {
    content: " ";
    display: table;
    clear: both;
  }
  &.fullscreen {
    + section.fullscreen.bgimageswiper, &.bgimageswiper {
      margin-top: 0;
      margin-bottom: 0;
    }
    //margin-top: 40px;
    margin-bottom: 40px;
    + {
      footer {
        margin-bottom: 40px;
      }
      section.fullscreen {
        margin-bottom: 40px;
        &.colorized {
          margin: 0;
          padding: 40px 0 40px 0;
        }
      }
    }
    &.colorized {
      margin: 0;
      padding: 40px 0 40px 0;
    }
  }
}

.col_padding-top {
  padding-top: 40px;
}

.lightgrey {
  background-color: #d7d5d4;
}

/**********************************************
	Colors
**********************************************/

.lightgrey-bg {
  background-color: #f5f5f5 !important;
}

.green {
  color: $green !important;
}

.green-bg {
  background-color: $green !important;
  color: #fff !important;
}
